import React from "react";
import { Helmet } from "react-helmet"

import PageWrapper from "../components/PageWrapper";
import { Box } from "../components/Core";
import About from "../sections/others/About";

const AboutPage = () => {
  return (
    <>
      <Helmet>
         <meta charSet="utf-8" />
         <title>BigDog.zone - About Us</title>
         <link rel="canonical" href={"https://bigdog.zone/about"} />
      </Helmet>
      <PageWrapper darkTheme>
        <Box pt={4}>
          <About />
        </Box>
      </PageWrapper>
    </>
  );
};

export default AboutPage;
